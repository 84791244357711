exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-gallery-tsx": () => import("./../../../src/pages/gallery.tsx" /* webpackChunkName: "component---src-pages-gallery-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-integrations-tsx": () => import("./../../../src/pages/integrations.tsx" /* webpackChunkName: "component---src-pages-integrations-tsx" */),
  "component---src-pages-legal-cookie-policy-mdx": () => import("./../../../src/pages/legal/cookie-policy.mdx" /* webpackChunkName: "component---src-pages-legal-cookie-policy-mdx" */),
  "component---src-pages-legal-data-processing-mdx": () => import("./../../../src/pages/legal/data-processing.mdx" /* webpackChunkName: "component---src-pages-legal-data-processing-mdx" */),
  "component---src-pages-legal-privacy-policy-mdx": () => import("./../../../src/pages/legal/privacy-policy.mdx" /* webpackChunkName: "component---src-pages-legal-privacy-policy-mdx" */),
  "component---src-pages-legal-security-mdx": () => import("./../../../src/pages/legal/security.mdx" /* webpackChunkName: "component---src-pages-legal-security-mdx" */),
  "component---src-pages-legal-terms-of-use-mdx": () => import("./../../../src/pages/legal/terms-of-use.mdx" /* webpackChunkName: "component---src-pages-legal-terms-of-use-mdx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-product-data-exploration-tsx": () => import("./../../../src/pages/product/data-exploration.tsx" /* webpackChunkName: "component---src-pages-product-data-exploration-tsx" */),
  "component---src-pages-product-data-modeling-tsx": () => import("./../../../src/pages/product/data-modeling.tsx" /* webpackChunkName: "component---src-pages-product-data-modeling-tsx" */),
  "component---src-pages-product-reporting-tsx": () => import("./../../../src/pages/product/reporting.tsx" /* webpackChunkName: "component---src-pages-product-reporting-tsx" */),
  "component---src-pages-where-does-count-fit-tsx": () => import("./../../../src/pages/where-does-count-fit.tsx" /* webpackChunkName: "component---src-pages-where-does-count-fit-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */)
}

